import { HoverState } from './styles'
import { Tooltip } from 'antd'

export const TooltipContainer = ({ children, title }) => {
    return (
        <Tooltip
            title={title}
            color="#333"
            overlayInnerStyle={{
                color: '#FFF',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '19.2px',
                letterSpacing: '0.12px',
                fontFamily: 'Readex Pro',
            }}
        >
            <HoverState>{children}</HoverState>
        </Tooltip>
    )
}
